import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";
import { parseISO, format } from 'date-fns';
import { NewsArticleQuery } from "../generated/graphql";
import Shell from "../layout/shell";

import * as styles from "./newsArticle.module.scss";
import { ClassNames } from "@rocketmakers/armstrong-edge";
import { Helmet } from "react-helmet";

interface INewsArticleProps {
  pageContext: {
    uid: string;
    readingTime: {
      text: string;
      minutes: number;
      time: number;
      words: number;
    };
  }
  data: NewsArticleQuery;
}

const NewsArticle: React.FC<INewsArticleProps> = ({ data: pageData, pageContext }) => {
  const source = pageData.prismicNewsArticle;
  const data = source?.data;
  if (!data) {
    return null;
  }

  console.log(data.links);

  return (
    <>
      <Helmet title={`HOP | News | ${data.title}`}>

        <script type="application/ld+json">
          {`
            {
              "@context": "http://www.schema.org",
              "@type": "BlogPosting",
              "headline": "${data.title}",
              "url": "https://www.homeofproduction.com/news/${source.uid}",
              "image": "${data.cover_image?.url}",
              "datePublished": "${source.last_publication_date}",
              "author": {
                "@type": "Person",
                "name": "${data.author_name}"
              }
            }
          `}
        </script>

      </Helmet>
      <Shell>
        <div className="fixed-width">
          <h1>{data.title}</h1>
        </div>
        <hr />
        <div className="fixed-width" style={{ paddingBottom: '100px'}}>
          <div className={styles.authorBlock}>
            <GatsbyImage className={styles.authorImage} image={data.author_image?.gatsbyImageData} alt={data.author_image?.alt || "Picture of author"} />
            <div>
              <div>{data.author_name}</div>
              <div className={styles.readBlock}>
                <div>{`Published ${format(parseISO(source.last_publication_date), "MMMM MM")}`}</div>
                <div>{pageContext.readingTime.text}</div>
              </div>
            </div>
          </div>
          <GatsbyImage alt={data.cover_image?.alt || 'Cover image'} image={data.cover_image?.gatsbyImageData} />
          <div className={styles.articleBody}>
            <p className="intro">{data.summary}</p>
            <PrismicRichText field={data.body?.richText} />
          </div>
          {data.links && data.links.length !== 0 && data.links[0]?.link_name &&
          <div className={styles.newsLinks}>
            <strong>References:</strong>
            {data.links?.map(link =>
              <a target="_blank" key={link?.link_name} href={link?.link?.url || '#'}>{link?.link_name}</a>
            )}
          </div>
        }
        </div>
       
      </Shell>
    </>
  )
}

export const query = graphql`
  query NewsArticle($uid: String!) {
    prismicNewsArticle(uid: { eq: $uid }) {
      last_publication_date
      uid
      data {
        title
        summary
        cover_image {
          gatsbyImageData
          alt
          url
        }
        body {
          richText
        }
        author_name
        author_image {
          alt
          gatsbyImageData
        }
        links {
          link {
            url
          }
          link_name
        }
      }
    }
  }
`
export default NewsArticle;